import * as React from 'react';
import {
  AutocompleteInput,
  Create,
  EditProps,
  Labeled,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useCreate,
  useNotify,
} from 'react-admin';
import {IconButton, InputAdornment, Stack, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {Company} from '../types';
import {ReferenceManyInput} from "@react-admin/ra-relationships";

interface Props extends EditProps<Company> {
  onCancel: () => void;
}

const CompanyCreate = ({onCancel, ...props}: Props) => {

  const [create] = useCreate(undefined, {meta: {}});
  const notify = useNotify();

  const handleCreateDomain = async (url: any) => {
    if (!url) return;

    try {
      return await create('domains', {data: {url}}, {
        returnPromise: true
      });
    } catch (e) {
      notify('Error: could not create domain', {
        type: 'error'
      });
      return;
    }
  }

  return (
    <Create {...props} mutationOptions={{meta: {}}}>
      <Stack direction="row" p={2}>
        <Typography variant="h6" flex="1">
          Add company
        </Typography>
        <IconButton onClick={onCancel} size="small">
          <CloseIcon/>
        </IconButton>
      </Stack>
      <SimpleForm sx={{pt: 0, pb: 0}}>
        <Labeled>
          <TextInput source={"name"}/>
        </Labeled>
        <Labeled>
          <ReferenceManyInput mutationOptions={{meta: {}}} reference={"company_contacts"} label="Contacts"
                              target={"company"}>
            <SimpleFormIterator inline>
              <TextInput source={"name"}/>
              <TextInput type={"email"} source={"email"}/>
              <TextInput type={"tel"} source={"telephone"}/>
            </SimpleFormIterator>
          </ReferenceManyInput>
        </Labeled>

        <Labeled>
          <ReferenceManyInput mutationOptions={{meta: {}}} reference={"company_comments"} label="Comments"
                              target={"company"}>
            <SimpleFormIterator inline>
              <TextInput source={"text"}/>
            </SimpleFormIterator>
          </ReferenceManyInput>
        </Labeled>

        <Labeled>
          <ReferenceManyInput mutationOptions={{meta: {}}} reference={"company_domain_products"} label="Domain products"
                              target={"company"}>
            <SimpleFormIterator inline>
              <ReferenceInput source={"domain"} reference={"domains"}>
                <AutocompleteInput onCreate={handleCreateDomain}/>
              </ReferenceInput>
              <TextInput source={"name"}/>
              <NumberInput
                source="price.amount"
                validate={required()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
            </SimpleFormIterator>
          </ReferenceManyInput>
        </Labeled>
      </SimpleForm>
    </Create>
  );
};

export default CompanyCreate;
