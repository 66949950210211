import * as React from 'react';
import {SolarLayout, SolarLayoutProps} from '@react-admin/ra-navigation';
import AppBar from './AppBar';
import CustomBreadcrumb from './Breadcrumb';

const Layout = (props: SolarLayoutProps) => {
  return (
    <SolarLayout appBar={AppBar} {...props}>
      <CustomBreadcrumb/>
      {props.children}
    </SolarLayout>
  );
};

export default Layout;
