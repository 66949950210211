import * as React from 'react';
import {
  Identifier,
  Datagrid,
  DateField,
  TextField,
} from 'react-admin';

import rowStyle from './rowStyle';

export interface CompanyListDesktopProps {
  selectedRow?: Identifier;
}

const CompanyListDesktop = ({selectedRow}: CompanyListDesktopProps) => (
  <Datagrid
    rowClick="edit"
    rowSx={rowStyle(selectedRow)}
    optimized
    sx={{
      '& .RaDatagrid-thead': {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
      },
      '& .column-comment': {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    }}
  >
    <DateField source="created"/>
    <TextField source="name"/>
    <TextField source="numComments"/>
    <TextField source="numDomains"/>
  </Datagrid>
);

export default CompanyListDesktop;
