import * as React from 'react';
import {
  Datagrid,
  DateField,
  EmailField,
  Labeled,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  ShowBase,
  ShowProps,
  TextField, UrlField,
} from 'react-admin';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Company } from '../types';

interface Props extends Partial<ShowProps<Company>> {
  onCancel: () => void;
}

const CompanyShow = ({ onCancel, ...props }: Props) => {
  return (
    <ShowBase {...props}>
      <Box width={{ xs: '100vw', sm: 400 }} pt={{ xs: 5, sm: 0 }} mt={{ xs: 2, sm: 0 }}>
        <Stack direction="row" p={2}>
          <Typography variant="h6" flex="1">
            Company details
          </Typography>
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box p={2}>
          <Labeled>
            <TextField source="name" />
          </Labeled>
          <Labeled>
            <ReferenceArrayField label="Contacts" source="contacts" reference="company_contacts">
              <Datagrid bulkActionButtons={false}>
                <DateField source="created" />
                <TextField source="name" />
                <TextField source="telephone" />
                <EmailField source="email" />
              </Datagrid>
            </ReferenceArrayField>
          </Labeled>
          <Labeled>
            <ReferenceArrayField label="Comments" source="comments" reference="company_comments">
              <Datagrid bulkActionButtons={false}>
                <DateField source="created" />
                <TextField source="text" />
              </Datagrid>
            </ReferenceArrayField>
          </Labeled>
          <Labeled>
            <ReferenceManyField label="Domains with products" target="company" reference="company_domain_products">
              <Datagrid bulkActionButtons={false}>
                <DateField source="created" />
                <ReferenceField source="domain" reference="domains">
                  <UrlField target="_blank" source="url" />
                </ReferenceField>
                <TextField source="price.amount" />
              </Datagrid>
            </ReferenceManyField>
          </Labeled>
        </Box>
      </Box>
    </ShowBase>
  );
};

export default CompanyShow;
