import React from 'react';
import {Box} from '@mui/material';

const Dashboard = () => {
  return (
    <Box>
      <h1>Dashboard to be done...</h1>
    </Box>
  )
};

export default Dashboard;
