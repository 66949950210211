import {parseHydraDocumentation as baseParseHydraDocumentation} from "@api-platform/api-doc-parser";
import {fetchHydra as baseFetchHydra, hydraDataProvider as baseHydraDataProvider} from "@api-platform/admin";
import {Api} from "@api-platform/api-doc-parser/src/Api";

export const ENTRYPOINT = process.env.ENTRYPOINT ?? "http://localhost"
const getHeaders = () => {
  const token = typeof window !== 'undefined' ? localStorage.getItem("token") : "";
  return {
    Authorization: `Bearer ${token || ""}`,
  };
};

const fetchHydra = async (url: any, options = {}) => {
  return await baseFetchHydra(url, {
    ...options,
    headers: getHeaders
  });
}

const apiDocumentationParser = () => async (entrypoint: string) => {
  try {
    return await baseParseHydraDocumentation(entrypoint, {headers: getHeaders});
  } catch (e: any) {
    if (e.status == 401) {
      localStorage.removeItem("token");
    }

    return e;
  }
};

const hydraDataProvider = () => baseHydraDataProvider({
  entrypoint: ENTRYPOINT,
  httpClient: fetchHydra,
  apiDocumentationParser: apiDocumentationParser(),
});

export default hydraDataProvider;
