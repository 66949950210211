import * as React from 'react';
import {localStorageStore, Resource, StoreContextProvider, useStore,} from 'react-admin';
import CssBaseline from '@mui/material/CssBaseline';

import authProvider from './authProvider';
import {Layout, Login} from './layout';
import {Dashboard} from './dashboard';
import {ThemeName, themes} from './themes/themes';
import companies from "./companies";
import {BrowserRouter} from "react-router-dom";
import hydraDataProvider, {ENTRYPOINT} from "./hydraDataProvider/hydraDataProvider";
import {HydraAdmin} from "@api-platform/admin";

const store = localStorageStore(undefined, 'RisersDigital');

const App = () => {
  const [themeName] = useStore<ThemeName>('themeName', 'soft');
  const lightTheme = themes.find(theme => theme.name === themeName)?.light;
  const darkTheme = themes.find(theme => theme.name === themeName)?.dark;

  return (
    <BrowserRouter>
      <HydraAdmin dataProvider={hydraDataProvider()}
                  requireAuth={true}
                  title=""
                  store={store}
                  authProvider={authProvider}
                  dashboard={Dashboard}
                  loginPage={Login}
                  layout={Layout}
                  disableTelemetry
                  lightTheme={lightTheme}
                  darkTheme={darkTheme}
                  entrypoint={ENTRYPOINT}>
        <CssBaseline/>
        <Resource name="companies" {...companies} />
      </HydraAdmin>
    </BrowserRouter>
  );
};

const AppWrapper = () => (
  <StoreContextProvider value={store}>
    <App/>
  </StoreContextProvider>
);

export default AppWrapper;
