import * as React from 'react';
import {useCallback} from 'react';
import {List} from 'react-admin';
import {matchPath, useLocation, useNavigate} from 'react-router-dom';
import {Box, Drawer} from '@mui/material';

import CompanyListDesktop from './CompanyListDesktop';
import companyFilters from './companyFilters';
import CompanyShow from "./CompanyShow";

const CompanyList = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate('/companies');
  }, [navigate]);

  const match = matchPath('/companies/:id', location.pathname);

  return (
    <Box display="flex">
      <List
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(['all'], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: !!match ? '400px' : 0,
          marginTop: -1,
        }}
        filters={companyFilters}
        perPage={25}
        sort={{field: 'date', order: 'DESC'}}
        exporter={false}
        hasCreate={true}
      >
        <CompanyListDesktop
          selectedRow={
            !!match
              ? parseInt((match as any).params.id, 10)
              : undefined
          }
        />
      </List>
      <Drawer
        variant="persistent"
        open={!!match}
        anchor="right"
        onClose={handleClose}
        sx={{zIndex: 100}}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!match && (
          <CompanyShow
            id={(match as any).params.id}
            onCancel={handleClose}
          />
        )}
      </Drawer>
    </Box>
  );
};

export default CompanyList;
