import {AuthProvider, UserIdentity} from 'react-admin';
import {ENTRYPOINT} from "./hydraDataProvider/hydraDataProvider";

const authProvider: AuthProvider = {
  login: () => {
    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem('username');
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () =>{
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getIdentity: () => {
    // decode token
    const decoded = JSON.parse(atob(localStorage.getItem("token")!.split(".")[1]));
    const id: UserIdentity = {
      id: decoded.username,
      fullName: decoded.name,
      avatar: decoded.avatar,
    };

    return Promise.resolve(id);
  },
  handleCallback: async () => {
    const query = window.location.search;
    if (query.includes('code=') && query.includes('state=')) {
      try {
        const response = await fetch(ENTRYPOINT + '/login/check-google' + query, {
          headers: {
            'Content-Type': 'application/ld+json',
            'Accept': 'application/ld+json'
          }
        });

        const data = await response.json();
        localStorage.setItem('token', data.token);
      } catch (error) {
        console.log('error', error);
        throw error;
      }
    }

    return {
      redirectTo: '/'
    }
  }
};

export default authProvider;
